/**
 * JSX icon.
 * 
 * IMPORTANT : to use svg with React, don't forget to convert <svg> attributes like 'sketch:type' TO 'sketchType', 
 * 'xmlns:xlink' TO 'xmlnsXlink' or 'xlink:href' TO 'xlinkHref' otherwise it won't work.
 */
const MicIcon = () => {
    return(
        <svg id="sectionIcon" enableBackground="new 0 0 64 64" version="1.1" viewBox="0 0 64 64" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <g>
                <path d="M29.726,40.861h4.549c4.19,0,7.587-3.396,7.587-7.586V7.688c0-4.19-3.396-7.586-7.587-7.586h-4.549   c-4.19,0-7.586,3.396-7.586,7.586v25.587C22.139,37.465,25.535,40.861,29.726,40.861z" />
                <path d="M46.295,23.976h-0.309c-1.26,0-1.367,1.68-1.373,2.113v8.117c0,5.361-4.344,9.705-9.703,9.705h-5.82   c-5.357,0-9.704-4.344-9.704-9.705v-8.632c0-1.659-1.397-1.597-1.397-1.597h-0.331c-0.453,0.047-1.209,0.353-1.209,1.906v9.337   c0,6.607,5.358,11.963,11.963,11.963h1.754v11.287c-0.006,0.336-0.129,1.373-1.438,1.385h-1.304c0,0-1.794-0.072-1.794,1.635v0.668   c0.009,0.379,0.157,1.742,1.937,1.742h8.843c1.725,0,1.941-1.105,1.959-1.553v-0.738c0-1.801-2.031-1.754-2.031-1.754H34.84   c-0.412-0.053-1.007-0.336-1.007-1.518V47.182h1.756c6.606,0,11.962-5.355,11.962-11.963v-9.693   C47.551,24.032,46.295,23.976,46.295,23.976z" />
            </g>
        </svg>
    );
}

export default MicIcon;
