/**
 * JSX icon.
 * 
 * IMPORTANT : to use svg with React, don't forget to convert <svg> attributes like 'sketch:type' TO 'sketchType', 
 * 'xmlns:xlink' TO 'xmlnsXlink' or 'xlink:href' TO 'xlinkHref' otherwise it won't work.
 */
const TurtleIcon = ({fill, size}) => {
    return(
        <svg width={size || "100%"} height="100%" style={{marginLeft: "5px"}} viewBox="0 0 225 180" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve" fillRule="evenodd">
            <g fill={fill || "#000"}>
                <path id="Body" d="M135.166,110.437l0,-0.002c0,0 23.843,-1.833 24.061,-29.409l0,-17.216c0,-0 -3.414,-30.084 24.341,-31.079c1.56,0.029 3.126,0.058 4.674,0.252c1.658,0.208 3.313,0.498 4.921,0.949c6.872,1.925 13.243,5.52 17.736,11.21c5.358,6.786 8.604,15.487 8.5,24.156c-0.023,1.875 -0.229,3.747 -0.474,5.607c-0.271,2.053 -0.673,4.088 -1.075,6.121c0,-0 -4.668,16.464 -22.44,17.453l-14.953,-0c-12.895,35.914 -45.291,35.838 -45.291,35.838l0,-0.002l-0.216,0.002l0,20.739c0,8.507 -6.907,15.414 -15.414,15.414l-16.945,0c-8.508,0 -15.415,-6.907 -15.415,-15.414l0,-20.739l-12.145,-0l0,20.739c0,8.507 -6.907,15.414 -15.414,15.414l-16.945,0c-8.508,0 -15.415,-6.907 -15.415,-15.414l-0,-20.739l-12.09,-0c-15.76,-0 -15.76,-23.882 -0,-23.882l119.729,0c0.091,0 0.182,0.001 0.27,0.002Zm53.833,-48.18c3.45,-0 6.251,2.777 6.251,6.198c0,3.421 -2.801,6.198 -6.251,6.198c-3.451,0 -6.252,-2.777 -6.252,-6.198c-0,-3.421 2.801,-6.198 6.252,-6.198Z" />
                <path id="Shell" d="M15.173,78.626c-0.047,-3.286 -0.048,-6.574 0.194,-9.848c0.759,-10.295 2.389,-16.985 5.736,-26.94c8.729,-25.963 33.548,-39.164 59.879,-39.146c24.022,0.017 45.874,9.966 57.08,31.884c5.379,10.522 7.917,22.488 8.634,34.218c0.201,3.282 0.154,6.575 0.06,9.863c0.067,2.322 0.089,4.647 -0.074,6.963c-0.357,5.079 -2.81,9.598 -7.711,11.629c-1.543,0.64 -3.241,0.978 -4.911,0.993c-35.407,0.316 -70.818,0.316 -106.224,-0c-7.258,-0.065 -12.136,-5.708 -12.622,-12.622c-0.156,-2.217 -0.213,-4.62 -0.041,-6.994Z" />
            </g>
        </svg>
    );
}

export default TurtleIcon;